/**
 * Slider init
 *
 * @since   1.0.0
 * @package ppd
 */

(function($) {
	$(document).ready(function() {

		var $slider = $( '.slider' );

		// sanity check
		if ( ! ( 'flickity' in $.fn ) || $slider.length < 1 ) {
			return;
		}


		// configure args
		var args = {
			cellSelector    : 'figure',
			prevNextButtons : false,
		};

		if ( $slider.hasClass( 'ken-burns' ) ) {
			args.autoPlay = 6000;
			args.pauseAutoPlayOnHover = false;
			args.on = {
				ready  : doNewBurn,
				change : doNewBurn,
			};
		}

		// kill!
		$slider = $slider.flickity( args );

		// latest slider info
		var flkty;


		/**
		 * Start new pan/zoom (burn)
		 *
		 * @param {event}   event
		 * @param {integer} index
		 */
		function doNewBurn( event, index ) {

			flkty = this;

			// start new slide
			flkty.selectedElement.classList.add( 'burn' );

			// clear old burns once this slide is done coming in
			flkty.selectedElement.addEventListener( 'transitionend', putOutBurns, false );
		}


		/**
		 * Clear out existing burns (except the current)
		 *
		 * @since 1.0.0
		 */
		function putOutBurns() {

			for ( i = 0; i < flkty.cells.length; i++ ) {

				if ( i != flkty.selectedIndex ) {
					flkty.cells[i].element.classList.remove( 'burn' );
				}
			}
		}

	}); // document.ready
})(jQuery);
