/**
 * Project Slider init
 *
 * @since   1.0.0
 * @package ppd
 */

(function($) {
	$(document).ready(function() {

		var $project_slider 			= $( '.project-slider' );
		var $project_thumbnail_slider 	= $( '.project-thumbnail-slider' );

		// sanity check
		if ( ! ( 'flickity' in $.fn ) || $project_slider.length < 1 ) {
			return;
		}


		// configure args
		var args = {
			cellSelector    : 'figure',
			prevNextButtons : true,
			lazyLoad		: true,
		};

		// kill!
		$project_slider = $project_slider.flickity( args );

		// configure thumbnail slider args
		var targs = {
			cellSelector    : 'figure',
			asNavFor		: '.project-slider',
			draggable		: true,
			contain			: true,
			pageDots		: false,
			prevNextButtons : false,
			lazyLoad		: true,
		};

		$project_thumbnail_slider = $project_thumbnail_slider.flickity( targs );

	}); // document.ready
})(jQuery);
