/**
 * Header on scroll stuff
 *
 * @since   1.0.0
 * @package ppd
 */
(function($) {

	var threshold = 220;
	var $header   = $( 'header[role=banner]' );
	var $window   = $( window );
	var waiting   = false, endScrollHandle;

	$window.scroll( handleScroll );


	/**
	 * Check scroll distance and add or remove class
	 */
	var checkScroll = function () {

		if ( $window.scrollTop() > threshold ) {
			$header.addClass( 'scrolled' );
		} else {
			$header.removeClass( 'scrolled' );
		}
	};


	/**
	 * Handle scroll
	 */
	function handleScroll() {

		if ( waiting ) {
			return;
		}
		waiting = true;
		$header.addClass( 'scrolling' );

		// clear previous scheduled endScrollHandle
		clearTimeout( endScrollHandle );

		checkScroll();

		setTimeout( function() {
			waiting = false;
		}, 40 );

		setTimeout( function() {
			$header.removeClass( 'scrolling' );
		}, 600 );

		// schedule an extra execution of scroll() after 160ms
		// in case the scrolling stops in next 40ms
		endScrollHandle = setTimeout( function() {
			checkScroll();
		}, 160 );
	}

})(jQuery);
