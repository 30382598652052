/**
 * Testimonial Slider init
 *
 * @since   1.0.0
 * @package ppd
 */

(function($) {
	$(document).ready(function() {

		var $testimonial_slider 			= $( '.video-slider-wrapper' );

		// sanity check
		if ( ! ( 'flickity' in $.fn ) || $testimonial_slider.length < 1 ) {
			return;
		}


		// configure args
		var args = {
			prevNextButtons : false,
			autoPlay		: 7000,
			adaptiveHeight	: true,
		};

		// kill!
		$testimonial_slider = $testimonial_slider.flickity( args );

	}); // document.ready
})(jQuery);
